import Vue from "vue";
import Backoffice from "@snark/backoffice";

import crudUserBusiness from "./crud/userBusiness";
import crudQuote from "./crud/quote";
import crudInvitation from "./crud/invitation";
import crudUserIndividual from "./crud/userIndividual";
import crudUserIndividualInProgress from "./crud/userIndividualInProgress";
import crudUserIndividualMeeting from "./crud/userIndividualMeeting";
import crudUserIndividualDone from "./crud/userIndividualDone";
import crudUserFeedbacks from "./crud/userFeedbacks";
import crudProfiling from "./crud/profiling";
import crudCard from "./crud/card";
import crudBouser from "./crud/bouser";
import crudUserConsultant from "./crud/userConsultants";

import moment from "moment-timezone";
import axios from "axios";

import ProfileScoreView from "@/components/ProfileScoreView.vue";

moment.tz.setDefault('Europe/Paris');
moment.locale('fr');

Vue.config.productionTip = false;

Vue.component("ProfileScoreView", ProfileScoreView);

Vue.use(Backoffice, {
    title: "Profil e-Diamant - BO",
    debug: true,
    apiUrl: process.env.VUE_APP_API_URL,
    apiVersion: 2,
    gmapApiKey: process.env.VUE_APP_GMAP_KEY,
    logAs: {
        token: process.env.VUE_APP_LOGAS_TOKEN,
        frontUrl: process.env.VUE_APP_LOGAS_FRONT_URL,
        apiRoute: process.env.VUE_APP_LOGAS_API_ROUTE
    },
    authenticate: async function (login, password) {
        try {
            const result = await axios.post(process.env.VUE_APP_API_AUTHENTICATE_URL, {
                username: login,
                password: password
            });
            if (result.data.token) {
                return {token: result.data.token};
            }
        } catch (err) {
            console.log(err);
        }
        throw "unauthorized";
    },
    crud: async function () {
        // Entreprises
        Vue.crud(crudUserBusiness);
        Vue.crud(crudQuote);
        Vue.crud(crudInvitation);

        // Particuliers
        Vue.crud(crudUserIndividual);
        Vue.crud(crudUserFeedbacks);

        // Profils
        Vue.crud(crudUserIndividualInProgress);
        Vue.crud(crudUserIndividualMeeting);
        Vue.crud(crudUserIndividualDone);
        Vue.crud(crudProfiling);

        // Base de données
        Vue.crud(crudCard);

        // Backoffice
        Vue.crud(crudUserConsultant);
        Vue.crud(crudBouser);
    },
    defaultCRUDOptions: {
        editor: {
            select: {
                sort: 'asc'
            },
            checks: {
                sort: 'asc'
            }
        }
    },
    //routes: {
    //    prepare: function (routes) {
    //    }
    //},
    menuSections: ["Entreprises", "Particuliers", "Profils", "Base de données", "Backoffice"],
    dashboardBlocks: []
});

Vue.backoffice.start(Vue);
