import vue from "vue";
import Backoffice from "@snark/backoffice";


let crud = {
    name: "quote",
    collection: "quote",
    idProperty: "_id",
    section: "Entreprises",
    labels: {
        "listTitle": "Devis",
        "singleTitle": "Devis"
    },
    pagination: 50,
    backTextFilterList: true,
    sort: {back: [{field: "createdAt", order: "desc"}]},
    emptyItem: {
        status: "created"
    },
    listColumns: [
        {
            "name": "createdAt",
            "label": "Date",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        },
        {
            "name": "companyName",
            "label": "Société"
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phone",
            "label": "Téléphone"
        },
        {
            "name": "profilesQuantity",
            "label": "Nombre de profils"
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                created: "Demande",
                sent: "Devis envoyé",
                accepted: "Accepté",
                canceled: "Refusé/annulé"
            })
        },
    ],
    fields: [

        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "companyName",
            "label": "Société"
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phone",
            "label": "Téléphone"
        },
        {
            "name": "profilesQuantity",
            "label": "Nombre de profils",
            editor: {
                type: "integer",
                min: 1
            }
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                created: "Demande",
                sent: "Devis envoyé",
                accepted: "Accepté",
                canceled: "Refusé/annulé"
            }),
            editor: {
                "type": "select",
                "options": [
                    {value: "created", label: "Demande"},
                    {value: "sent", label: "Devis envoyé"},
                    {value: "accepted", label: "Accepté"},
                    {value: "canceled", label: "Refusé/annulé"},
                ]
            }
        },

        {
            "name": "createdAt",
            "label": "Demandé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            condition: item => {
                return item.status === "sent";
            },
            "name": "sentAt",
            "label": "Envoyé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            readOnly: true,
            "hideOnCreate": true
        },
        {
            condition: item => {
                return item.status === "accepted";
            },
            "name": "acceptedAt",
            "label": "Accepté le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            readOnly: true,
            "hideOnCreate": true
        },
        {
            condition: item => {
                return item.status === "canceled";
            },
            "name": "canceledAt",
            "label": "Refusé/annulé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            readOnly: true,
            "hideOnCreate": true
        },

        {
            name: "documentPath",
            label: "Devis",
            formatter: (field, item) => {
                return item.documentPath ? ('<a href="' + process.env.VUE_APP_API_STATIC_URL + item.documentPath + '" target="_blank">' + item.documentPath + '</a>') : "-";
            },
            editor: {
                type: "file",
                accept: "application/pdf"
            }
        },


        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ],
    viewActions: [
        {
            label: "Devis validé",
            cssClass: "Good",
            check: (item) => {
                return item.status !== 'accepted';
            },
            action: async (item) => {
                console.log("ITEM: ", item);
                console.log("BO = ", vue.backoffice);
                await vue.backoffice.crudRequestor.update("quote", item._id, {status: "accepted"});
                return {reload: true};
            }
        }
    ]
};
export default crud;
