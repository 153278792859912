import Backoffice from "@snark/backoffice";


let crud = {
    name: "card",
    collection: "card",
    idProperty: "_id",
    section: "Base de données",
    labels: {
        "listTitle": "Cartes",
        "singleTitle": "Carte"
    },
    pagination: 54,
    backTextFilterList: true,
    sort: {back: [{field: "number"}]},
    emptyItem: {
        words: [],
        profile: "CA"
    },
    listColumns: [
        {
            "name": "number",
            "label": "Numéro"
        },
        {
            "name": "words",
            "label": "Mot clés",
            formatter: Backoffice.formatters.tags(undefined, "")
        },
        {
            "name": "profile",
            "label": "Type de profil",
            formatter: Backoffice.formatters.mapping({
                "CA": "Cartésien",
                "EM": "Émotif",
                "OP": "Opiniâtre",
                "BA": "Battant",
                "SP": "Spontané",
                "PE": "Pensif",
            })
        },
        {
            "name": "state",
            "label": "Etat",
            formatter: Backoffice.formatters.mapping({
                null: "-",
                "deleted": "désactivée",
            }),
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "number",
            "label": "Numéro",
            editor: {
                type: "integer",
                min: 1
            }
        },
        {
            "name": "words",
            "label": "Mot clés",
            list: true,
            listEmptyItem: "",
            children: [
                {
                    name: "_item"
                }
            ]
        },
        {
            "name": "profile",
            "label": "Type de profil",
            formatter: Backoffice.formatters.mapping({
                "CA": "Cartésien",
                "EM": "Émotif",
                "OP": "Opiniâtre",
                "BA": "Battant",
                "SP": "Spontané",
                "PE": "Pensif",
            }),
            editor: {
                "type": "select",
                "options": [
                    {value: "CA", label: "Cartésien"},
                    {value: "EM", label: "Emotif"},
                    {value: "OP", label: "Opiniâtre"},
                    {value: "BA", label: "Battant"},
                    {value: "SP", label: "Spontané"},
                    {value: "PE", label: "Pensif"}
                ]
            }
        },

        {
            "name": "state",
            "label": "Etat",
            formatter: Backoffice.formatters.mapping({
                null: "-",
                "deleted": "déasctivée",
            }),
            editor: {
                "type": "select",
                "options": [
                    {value: null, label: "actif"},
                    {value: "deleted", label: "désactivé"}
                ]
            }
        },

        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};
export default crud;
