import Backoffice from "@snark/backoffice";
import {userFields} from "./userFields";

const context = {
    profiling: Backoffice.populator('profiling'),
};

let crud = {
    name: "userFeedbacks",
    collection: "user",
    idProperty: "_id",
    section: "Particuliers",
    labels: {
        "listTitle": "Feedbacks",
        "singleTitle": "Feedback"
    },
    pagination: 50,
    backTextFilterList: true,
    sort: {back: [{field: "feedback.date", order: "desc"}]},
    backFilter: {
        role: "individual",
        status: "feedbackDone"
    },
    noCreate: true,
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                apiBase: process.env.VUE_APP_API_BASE_URL,
                field: "email"
            }
        }
    ],
    listColumns: [
        {
            "name": "feedback_date",
            "label": "Date",
            formatter: (field, item) => Backoffice.formatters.date("DD/MM/YYYY")({name: "date"}, item.feedback)
        },
        {
            "name": "feedback_rating1",
            "label": "Reconnu dans le profil",
            formatter: (field, item) => item.feedback.rating1
        },
        {
            "name": "feedback_rating2",
            "label": "Améliorer situation de stress",
            formatter: (field, item) => item.feedback.rating2
        },
        {
            "name": "feedback_rating3",
            "label": "Améliorer communication",
            formatter: (field, item) => item.feedback.rating3
        },
        {
            "name": "feedback_rating4",
            "label": "Gain efficacité",
            formatter: (field, item) => item.feedback.rating4
        }
    ],
    fields: userFields,
    completeSingle: context.profiling.completeAssociatedList("userId", "_id", "profiling")
};

export default crud;
