import Backoffice from "@snark/backoffice";


let crud = {
    name: "userConsultants",
    collection: "user",
    idProperty: "_id",
    section: "Backoffice",
    labels: {
        "listTitle": "Consultants",
        "singleTitle": "Consultant"
    },
    pagination: 50,
    sort: {back: [{field: "lastname"}, {field: "firstname"}]},
    backFilter: {
        role: "consultant"
    },
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                apiBase: process.env.VUE_APP_API_BASE_URL,
                field: "email"
            }
        }
    ],
    listColumns: [
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phoneNumber",
            "label": "Téléphone",
        },
        {
            "name": "createdAt",
            "label": "Date d'enregistrement",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phoneNumber",
            "label": "Téléphone"
        },
        {
            "name": "password",
            "label": "Nouveau mot de passe",
            writeOnly: true
        },

        {
            "name": "state",
            "label": "Désactivé ?",
            editor: {
                "type": "select",
                "options": [
                    {value: null, label: "actif"},
                    {value: "deleted", label: "désactivé"}
                ]
            },
            hideOnCreate: true,
        },

        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
    ],
    emptyItem: {
        role: "consultant",
        status: "created",
        cguAccepted: true
    }
};
export default crud;
