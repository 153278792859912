<script>
import {defineComponent} from 'vue'

const profileTranslation = {
	"CA": "Cartésien",
	"EM": "Émotif",
	"OP": "Opiniâtre",
	"BA": "Battant",
	"SP": "Spontané",
	"PE": "Pensif"
};

export default defineComponent({
	name: "ProfileScoreView",

	props: {
		id: String,
		score: Object
	},
	data () {
		return {};
	},
	computed: {
		statusName() {
			if(this.score) {
				switch (this.score.status) {
					case "SufficientProfiling":
						return "Nombre de profilages suffisants";
					case "AllProfiling":
						return "Profilages complets";
					case "InsufficientProfiling":
					default:
						return "Nombre de profilages insuffisants";
				}
			}
			else {
				return "-";
			}
		},
		mainType() {
			return this.score.mainType ? profileTranslation[this.score.mainType]: "-";
		},
		secondaryType() {
			return this.score.secondaryType ? profileTranslation[this.score.secondaryType]: "-";
		},

		percents() {
			if(this.score.typesPercents) {
				let percents = [];
				for(const key of Object.keys(profileTranslation)) {
					if(this.score.typesPercents[key]) {
						percents.push({
							profile: key,
							profileName: profileTranslation[key],
							percent: this.score.typesPercents[key],
							percentString: this.score.typesPercents[key].toFixed(1) + "%"
						});
					}
				}
				if(percents.length > 0) {
					percents.sort((a, b) => {
						return b.percent - a.percent;
					});
					return percents;
				}
				else {
					return null;
				}
			}
			else {
				return null;
			}
		},

		pieUrl() {
			return process.env.VUE_APP_API_BASE_URL + "/user-profile-pie/" + this.id;
		}
	},
	methods: {

	},

	mounted() {
		console.log("Score = ", this.score);
	}
})
</script>

<template>
	<div v-if="score" class="ProfileScoreView">
		<div class="info">
			<div :class="'status ' + score.status">{{statusName}}</div>
			<div class="FieldView count">
				<div class="FieldViewLabel">Nb Profilages</div>
				<div class="FieldViewComponent">{{score.profilingCount}}</div>
			</div>
		</div>
		<div class="types">
			<div class="FieldView">
				<div class="FieldViewLabel">Coeur de diamant</div>
				<div class="FieldViewComponent">{{mainType}}</div>
			</div>
			<div class="FieldView">
				<div class="FieldViewLabel">Profil secondaire</div>
				<div class="FieldViewComponent">{{secondaryType}}</div>
			</div>
		</div>
		<div class="result" v-if="percents">
			<div class="percents">
				<div v-for="item in percents" :key="item.profile" class="percent">
					<div class="profile">{{item.profileName}}</div>
					<div class="percent">{{item.percentString}}</div>
				</div>
			</div>
			<div class="pie">
				<img :src="pieUrl" alt="Camenbert Profil" crossorigin="anonymous"/>
			</div>
		</div>

	</div>
</template>

<style lang="scss">
	@import "../../node_modules/@snark/backoffice/src/sass/variables.scss";

	.ProfileScoreView {
		border: $borderColor solid 1px;
		border-radius: 8px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
		padding: $gap;

		.info {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.status {
				padding: 5px 10px;
				background-color: $title1Color;
				color: white;
				border-radius: 100px;

				&.InsufficientProfiling {
					background-color: $buttonDangerBackground
				}

				&.AllProfiling {
					background-color: $buttonGoodBackground;
				}
			}

			.count {
				padding: 10px;
				border-radius: 8px;
				border: $title1Color solid 1px;
				color: $title1Color;
				font-size: 30px;
				display: flex;
				flex-direction: column;
				align-items: center;

				.FieldViewComponent {
					margin-top: 10px;
				}
			}
		}

		.types {
			border-top: $borderColor solid 1px;
			padding-top: 10px;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			gap: 30px;
			margin-bottom: 20px;
			font-weight: bold;
		}

		.result {
			display: flex;
			gap: 30px;

			.percents {
				padding: 10px;
				border-radius: 8px;
				border: $title1Color solid 1px;

				.percent {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 16px;
					padding: 5px 0;

					.profile {
						color: $textColor;

					}

					.percent {
						font-weight: bold;
						margin-left: 20px;
					}
				}
			}

			.pie {
				flex: 1 1 0;

				img {
					display: block;
					width: 100%;
					height: 100%;
					max-height: 250px;
					object-fit: contain;
				}
			}
		}

	}
</style>