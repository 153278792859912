import Backoffice from "@snark/backoffice";


let crud = {
    name: "invitation",
    collection: "invitation",
    idProperty: "_id",
    section: "Entreprises",
    labels: {
        "listTitle": "Invitations",
        "singleTitle": "Invitation"
    },
    pagination: 50,
    backPopulateList: ["user"],
    backTextFilterList: true,
    sort: {back: [{field: "createdAt", order: "desc"}]},
    readOnly: true,
    listColumns: [
        {
            "name": "createdAt",
            "label": "Date",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        },
        {
            "name": "userFirstname",
            "label": "Prénom"
        },
        {
            "name": "userLastname",
            "label": "Nom"
        },
        {
            "name": "companyName",
            "label": "Société"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "sent": "Envoyée",
                "used": "Utilisée"
            })
        },
        {
            "name": "registeredAt",
            "label": "Date inscription",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        },
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "createdAt",
            "label": "Date d'envoi",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        },
        {
            "name": "userId",
            "label": "Emetteur",
            formatter: Backoffice.formatters.linkObjectId("userBusiness", {
                label: "Voir l'émetteur",
            })
        },
        {
            "name": "companyName",
            "label": "Société"
        },
        {
            "name": "guestId",
            "label": "Utilisateur inscrit",
            formatter: Backoffice.formatters.linkObjectId("userIndividual", {
                label: Backoffice.formatters.concat(
                    Backoffice.formatters.rootProperty("userFirstname"),
                    " ",
                    Backoffice.formatters.rootProperty("userLastname")
                )
            })
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "registeredAt",
            "label": "Date inscription",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        },

        {
            "name": "status",
            "label": "Statut",
            formatter: Backoffice.formatters.mapping({
                "sent": "Envoyée",
                "used": "Utilisée"
            })
        },

        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};
export default crud;
