import Backoffice from "@snark/backoffice";

export default {
    name: "bouser",
    collection: "bouser",
    idProperty: "_id",
    section: "Backoffice",
    labels: {
        "listTitle": "Utilisateurs Backoffice",
        "singleTitle": "Utilisateur Backoffice"
    },
    emptyItem: {},
    listColumns: [
        {
            "name": "username",
            "label": "Username"
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "username",
            "label": "Username"
        },
        {
            "name": "password",
            "label": "Nouveau mot de passe",
            writeOnly: true
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ]
};
