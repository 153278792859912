import Backoffice from "@snark/backoffice";


let crud = {
    name: "userBusiness",
    collection: "user",
    idProperty: "_id",
    section: "Entreprises",
    labels: {
        "listTitle": "Clients Entreprise",
        "singleTitle": "Client Entreprise"
    },
    pagination: 50,
    backTextFilterList: true,
    sort: {back: [{field: "companyName"}]},
    backFilter: {
        role: "business"
    },
    viewActions: [
        {
            type: 'logAs',
            logAs: {
                apiBase: process.env.VUE_APP_API_BASE_URL,
                field: "email"
            }
        }
    ],
    // loadSingle,
    listColumns: [
        {
            "name": "companyName",
            "label": "Société"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "status",
            "label": "Etat",
            formatter: Backoffice.formatters.mapping({
                "created": "Créé",
                "awaitingQuote": "En attente de devis",
                "canceled": "Annulé",
                "client": "Client"
            })
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phoneNumber",
            "label": "Téléphone",
        },
        {
            "name": "createdAt",
            "label": "Date d'inscription",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "firstname",
            "label": "Prénom"
        },
        {
            "name": "lastname",
            "label": "Nom"
        },
        {
            "name": "email",
            "label": "Email"
        },
        {
            "name": "phoneNumber",
            "label": "Téléphone"
        },
        {
            "name": "password",
            "label": "Nouveau mot de passe",
            hideOnCreate: true,
            writeOnly: true
        },

        {
            "name": "companyName",
            "label": "Nom de l'entreprise"
        },
        {
            "name": "siretNumber",
            "label": "SIRET"
        },
        {
            "name": "jobTitle",
            "label": "Fonction"
        },

        {
            "name": "status",
            "label": "Etat",
            formatter: Backoffice.formatters.mapping({
                "created": "Créé",
                "awaitingQuote": "Attente du devis",
                "client": "Client",
                "canceled": "Annulé"
            }),
            editor: {
                "type": "select",
                "options": [
                    {value: "created", label: "Créé"},
                    {value: "awaitingQuote", label: "Attente du devis"},
                    {value: "client", label: "Client"},
                    {value: "canceled", label: "Annulé"}
                ]
            }
        },
        {
            "name": "profilesLeft",
            "label": "Profils restants",
            editor: {
                type: "integer"
            }
        },
        {
            "name": "profilesUsed",
            "label": "Profils utilisés",
            readOnly: true
        },

        {
            "name": "state",
            "label": "Désactivé ?",
            editor: {
                "type": "select",
                "options": [
                    {value: null, label: "actif"},
                    {value: "deleted", label: "désactivé"}
                ]
            },
            hideOnCreate: true,
        },

        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
    ],
    emptyItem: {
        role: "business",
        status: "awaitingQuote"
    }
};
export default crud;
