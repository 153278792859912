import Backoffice from "@snark/backoffice";


let crud = {
    name: "profiling",
    collection: "profiling",
    idProperty: "_id",
    section: "Profils",
    labels: {
        "listTitle": "Détail profilages",
        "singleTitle": "Détail profilage"
    },
    pagination: 50,
    backPopulateList: ["user"],
    backTextFilterList: true,
    sort: {back: [{field: "createdAt", order: "desc"}]},
    listColumns: [
        {
            "name": "user",
            label: "Sujet",
            formatter: Backoffice.formatters.fullName()
        },
        {
            "name": "profilerName",
            "label": "Nom"
        },
        {
            "name": "profilerEmail",
            "label": "Email"
        },
        {
            "name": "createdAt",
            "label": "Date",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            "name": "status",
            "label": "Etape",
            formatter: Backoffice.formatters.mapping({
                'started': "Démarré",
                'completed': "Complet"
            })
        }
    ],
    fields: [
        {
            "name": "_id",
            "label": "Identifiant",
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "profilerName",
            "label": "Nom"
        },
        {
            "name": "profilerEmail",
            "label": "Email"
        },
        {
            "name": "createdAt",
            "label": "Date",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY")
        },
        {
            "name": "user",
            label: "Sujet",
            formatter: Backoffice.formatters.fullName(),
            readOnly: true
        },
        {
            "name": "status",
            "label": "Etape",
            formatter: Backoffice.formatters.mapping({
                'started': "Démarré",
                'completed': "Complet"
            }),
            editor: {
                "type": "select",
                "options": [
                    {value: "started", label: "Démarré"},
                    {value: "completed", label: "Complet"}
                ]
            }
        },
        {
            "name": "cardNumbers",
            label: "Cartes choisies",
            readOnly: true,
            children: [
                {
                    "name": "undiscovered",
                    label: "Non découvertes",
                    formatter: Backoffice.formatters.tags(undefined, "")
                },
                {
                    "name": "dismissed",
                    label: "Ecartées (étape 1)",
                    formatter: Backoffice.formatters.tags(undefined, "")
                },
                {
                    "name": "selected27",
                    label: "Sélection 27",
                    formatter: Backoffice.formatters.tags(undefined, "")
                },
                {
                    "name": "selected13",
                    label: "Sélection 13",
                    formatter: Backoffice.formatters.tags(undefined, "")
                }
            ]
        },
        {
            "name": "createdAt",
            "label": "Créé le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        },
        {
            "name": "updatedAt",
            "label": "Modifié le",
            "formatter": Backoffice.formatters.date("DD/MM/YYYY"),
            "readOnly": true,
            "hideOnCreate": true
        }
    ],
    emptyItem: {
        cardNumbers: {
            undiscovered: [
                1, 2, 3, 4, 5, 6, 7, 8, 9,
                10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
                20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
                30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
                40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
                50, 51, 52, 53, 54
            ],
            dismissed: [],
            selected27: [],
            selected13: [],
            dismissed14: []
        },
        status: 'started'
    }
};
export default crud;

